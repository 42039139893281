//$(document).ready(function(){
	

var browser=get_browser_info();
//alert(browser.name + " " + browser.version);
//alert(browser.version);
if(browser.name == "Safari" && browser.version < 6){
	var appendCssSafari = '<link rel="stylesheet" href="../assets/css/safari.css">';
	document.getElementsByTagName('head')[0].innerHTML += appendCssSafari;

	//$('head').append(appendCssSafari);
	//alert(browser.name);
	//alert(browser.version);
	if(browser.name == "Safari" && browser.version < 5){

	 document.getElementById('header').innerHTML += '<div onclick="openMenu()" class="mdl-layout__drawer-button">&#9776;</div>';
	 
	 document.getElementById('sonhos-drawer').innerHTML += '<a onclick="closeMenu()" class="close-menu-fix">&#215;</a>';

	 function closeMenu() {
		document.getElementById('sonhos-drawer').setAttribute("style", "transform: translateX(-250px)");
	 }

	 function openMenu() {
		document.getElementById('sonhos-drawer').setAttribute("style", "transform: translateX(0)");
	 }
 }
}

if(browser.name == "Safari" && browser.version < 9){
	function sidebarContent() {
		var sidebar = document.getElementsById("sidebar");
		var sidebarContent = sidebarContent.innerHTML;
		if($(window).width() < 480){
			sidebar.parentElement.removeChild(elem);
			//$(".sidebar-main").remove();
			//$(".content-main").before(sidebarContent);
			document.getElementById('sonhos-content').innerHTML += sidebarContent;
		}
	}
	sidebarContent();
}

if(browser.name == "MSIE" && browser.version < 10){

 //var appendCssSafari = '<div class="mdl-layout__drawer-button">&#9776;</div>';
 //$('header.sonhos-header').append('<div class="mdl-layout__drawer-button">&#9776;</div>');
 document.getElementById('header').innerHTML += '<div class="mdl-layout__drawer-button">&#9776;</div>';

	//$('.sonhos-content').after('<div class="mdl-layout__obfuscator"></div>');

	function insertAfter(referenceNode, newNode) {
		referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
	}

	el = '<div class="mdl-layout__obfuscator"></div>';
	var div = document.getElementById("sonhos-content");
	insertAfter(div, el);


	var drawerButtonClick =  document.getElementsByClassName('mdl-layout__drawer-button')[0];
	drawerButtonClick.addEventListener("click", function(){
		document.getElementsByClassName('sonhos-drawer')[0].setAttribute('style','-ms-transform', 'translateX(0)');
		document.getElementsByClassName('mdl-layout__obfuscator')[0].setAttribute('style','visibility', 'visible');
	});

	// $('.mdl-layout__drawer-button').on("click",function(){
	//  $('.sonhos-drawer').css('-ms-transform', 'translateX(0)');
	//  $('.mdl-layout__obfuscator').css('visibility', 'visible');
	// });

	var obfuscatorClick = document.getElementsByClassName('mdl-layout__obfuscator')[0];
	obfuscatorClick.addEventListener("click", function(){
		document.getElementsByClassName('sonhos-drawer')[0].setAttribute('style','-ms-transform', 'translateX(-250px)');
		this.setAttribute('style','visibility', 'hidden');
	});

	// $('.mdl-layout__obfuscator').on("click",function(){
	//  $('.sonhos-drawer').css('-ms-transform', 'translateX(-250px)');
	//  $(this).css('visibility', 'hidden');
	// });
	var icons = document.getElementsByClassName("material-icons");
	for (var i = 0; i < icons.length; i++) {
		var iconType = this.innerHTML;
		if(iconType == 'search'){this.innerHTML = '';}
		if(iconType == 'more_vert'){this.innerHTML = '⋮'}
	}

	// $('.material-icons').each(function(){
	//  var iconType = $(this).text();
	//  if(iconType == 'search'){$(this).text('')}
	//  if(iconType == 'more_vert'){$(this).text('⋮')}
	// });
}
//FIM verifica o browser e versão

//manipulando a busca no mobile para esconder o logo se houver texto no input
var inputFieldSearch = document.getElementsByClassName("mdl-textfield__input")[0].value;
//var inputFieldSearch = $(".mdl-textfield__input").val();
if (inputFieldSearch.length > 0){
	document.getElementsByClassName("header-app-sonhos__logo")[0].className += " hide";
}
// if (inputFieldSearch.length > 0){$(".header-app-sonhos__logo").addClass("hide");}

document.getElementsByClassName("mdl-textfield__input")[0].addEventListener("blur", function(){
	var inputFieldSearch = document.getElementsByClassName("mdl-textfield__input")[0].value;
	if (inputFieldSearch.length > 0){
		document.getElementsByClassName("header-app-sonhos__logo")[0].className += " hide";
	}else{
		document.getElementsByClassName("header-app-sonhos__logo")[0].className = "header-app-sonhos__logo";
	}
}); 

// $(".mdl-textfield__input").on("blur", function(){
//   var inputFieldSearch = $(".mdl-textfield__input").val();
//   if (inputFieldSearch.length > 0){
//     $(".header-app-sonhos__logo").addClass("hide");
//   }else{
//     $(".header-app-sonhos__logo").removeClass("hide");
//   }
// });
//});

window.smoothScroll = function(target) {
	var scrollContainer = target;
	do { //find scroll container
		scrollContainer = scrollContainer.parentNode;
		if (!scrollContainer) return;
		scrollContainer.scrollTop += 1;
	} while (scrollContainer.scrollTop == 0);
	
	var targetY = 0;
	do { //find the top of target relatively to the container
		if (target == scrollContainer) break;
		targetY += target.offsetTop;
	} while (target = target.offsetParent);
	
	scroll = function(c, a, b, i) {
		i++; if (i > 30) return;
		c.scrollTop = a + (b - a) / 30 * i;
		setTimeout(function(){ scroll(c, a, b, i); }, 20);
	}
	// start scrolling
	scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
}


var fixedScrollAds = document.getElementById("fixedScrollAds").offsetTop;
var fixedScrollAdsLeft = document.getElementById("fixedScrollAds").offsetLeft;
var sideBarWidth = document.getElementById("sidebar").getBoundingClientRect().width;

function scrollTopPosition() {
	var body = document.getElementById("mdlLayoutContainer");
	var windowHeight = window.innerHeight;
	var topPosition = body.scrollTop;
	var positionHeight = windowHeight + topPosition;
	if(positionHeight > 2000){
		document.getElementById("to-top").style.display="flex";
	}else{
		document.getElementById("to-top").style.display="none";
	}
	if (topPosition >= fixedScrollAds) {
		document.getElementById("fixedScrollAds").setAttribute(
		"style", "position: fixed; top:16px; width:" + sideBarWidth +"px; left:" + fixedScrollAdsLeft + "px");
	}else{
		document.getElementById("fixedScrollAds").setAttribute(
		"style", "position: relative; width: auto; top: auto; left:auto");
	}
	//console.log(fixedScrollAds);
} 

//hover no link do menu
function showSubMenu(e) {
	var menuToShow =  e.getAttribute("data-menu");
	document.getElementById(menuToShow).style.display="block";
}

//hover out no link do menu
function hideSubMenu(e) {
	var menuToShow =  e.getAttribute("data-menu");
	document.getElementById(menuToShow).style.display="none";
}

//armazena as classes anteriores a adição da .active
var classListMenu;
//hover no mega menu
function subMenuOn(e) {
	var linkOn =  e.getAttribute("id");
	window.classListMenu = document.querySelector('[data-menu="'+linkOn+'"]').className;
	document.querySelector('[data-menu="'+linkOn+'"]').className+=" active";
}

//hover out no mega menu
function subMenuOff(e) {
	var linkOn =  e.getAttribute("id");
	document.querySelector('[data-menu="'+linkOn+'"]').className=classListMenu;
}

//verifica o browser e versão
function get_browser_info(){
	var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	if(/trident/i.test(M[1])){
			tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
			return {name:'IE',version:(tem[1]||'')};
			}
	if(M[1]==='Chrome'){
			tem=ua.match(/\bOPR\/(\d+)/)
			if(tem!=null)   {return {name:'Opera', version:tem[1]};}
			}
	M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
	if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
	return {
		name: M[0],
		version: M[1]
	};
}
